
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import type { ElForm } from "element-plus";
import { Banner } from "@/store/modules/BannerModule"
import { Plus, Delete } from "@element-plus/icons-vue"
import type { ElUpload } from "element-plus"

type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  components: {
    Plus,
    Delete,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("轮播图管理", ["轮播图管理"])
    })

    const rules = ref({
      // name: [
      //   {
      //     required: true,
      //     message: "商圈名称不可为空",
      //     trigger: "change",
      //   },
      // ],
    })

    const formRef = ref<FormInstance>();
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const onSubmit = (formEl: FormInstance | undefined) => {
      if (!formEl) return

      let params = {
        banners: {
          banners: banners.value
        },
      }

      formEl.validate((valid) => {
        if (valid) {
          store
            .dispatch(
              Actions.UPDATE_BANNERS,
              params
            )
            .then(() => {
              // router.push({ name: "business_districts" });
              Swal.fire({
                text: "保存成功",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "确认",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            })
            .catch(() => {
              // const [error] = Object.keys(store.getters.getErrors);
              // Swal.fire({
              //   text: store.getters.getErrors[error],
              //   icon: "error",
              //   buttonsStyling: false,
              //   confirmButtonText: "Try again!",
              //   customClass: {
              //     confirmButton: "btn fw-bold btn-light-danger",
              //   },
              // });
            });
        } else {
          return false
        }
      })
    }

    const submitFileList = [] as any;
    const fileList = ref([] as any);

    const handleRemove = (item) => {
      item._destroy = 1
    }
    
    const onImageChange = (file, item) => {
      var reader = new FileReader()
      reader.readAsDataURL(file.raw)

      reader.onload = (e) => {
        item.image = e.target?.result
        item.image_url = e.target?.result
      }
    }

    const onIndexImageChange = (file, item) => {
      var reader = new FileReader()
      reader.readAsDataURL(file.raw)

      reader.onload = (e) => {
        item.index_image = e.target?.result
        item.index_image_url = e.target?.result
      }
    }

    const onDetailImageChange = (file, item) => {
      var reader = new FileReader()
      reader.readAsDataURL(file.raw)

      reader.onload = (e) => {
        item.detail_image = e.target?.result
        item.detail_image_url = e.target?.result
      }
    }

    const banners = ref(Array<Banner>())

    store
      .dispatch(Actions.GET_BANNERS)
      .then(() => {
        banners.value = store.getters.currentBanners
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // })
      })

    const uploadRef = ref<InstanceType<typeof ElUpload>>()

    const onNew = (item) => {
      banners.value.push({} as Banner)
    }

    return {
      uploadRef,
      onSubmit,
      rules,
      formRef,
      onImageChange,
      onIndexImageChange,
      onDetailImageChange,
      handleRemove,
      fileList,
      banners,
      onNew
    };
  },
});
